import { t, Trans } from '@lingui/macro';
import { isEqual, isPresent, uniqueBy } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    CenteredLayout,
    chainComparators,
    Checkbox,
    colorSystem,
    Comparable,
    compareByNumber,
    FieldText,
    Flexbox,
    SecondaryButton,
    Tag,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierTag } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO, hasSupplierTag } from '@luminovo/sourcing-core';
import { Search, StarBorderRounded, StarRounded } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import { useOrganizationSolutionPreferences } from '../../../resources/organizationSettings/organizationSolutionPreferenceHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { SourcingScenarioFormValues } from './converters';

type DisplayMode = 'approved' | 'notApproved';
type SelectionStatus = 'none' | 'all' | 'indeterminate';

function createSupplierComparatorByIndex(
    allSuppliersAndStockLocations: SupplierAndStockLocationDTO[],
    preferredSupplierAndStockLocationIds: string[],
): Comparable<string> {
    const indexMap = new Map<string, number>();
    allSuppliersAndStockLocations.forEach((obj, index) => {
        indexMap.set(obj.id, index);
    });
    const preferredIds = new Set(preferredSupplierAndStockLocationIds);
    return chainComparators(
        compareByNumber((item) => (preferredIds.has(item) ? 0 : 1)),
        compareByNumber((item) => indexMap.get(item) ?? 0),
    );
}

function useWatchSuppliersAndStockLocations() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const preferredSuppliersAndStockLocations =
        useWatch({
            control,
            name: 'solution_preference.preferred_suppliers_and_stock_locations',
        }) ?? [];
    const approvedSuppliersAndStockLocations =
        useWatch({
            control,
            name: 'solution_preference.approved_suppliers_and_stock_locations',
        }) ?? [];

    const nonApprovedSupplierAndStockLocations = supplierAndStockLocations
        .map((sasl) => sasl.id)
        .filter(
            (id) =>
                !preferredSuppliersAndStockLocations.includes(id) && !approvedSuppliersAndStockLocations.includes(id),
        );

    return {
        preferredSuppliersAndStockLocations,
        approvedSuppliersAndStockLocations,
        nonApprovedSupplierAndStockLocations,
        supplierAndStockLocations,
    };
}

const DisplayModeButtonGroup: React.FunctionComponent<{
    displayMode: DisplayMode;
    onClick: (value: DisplayMode) => void;
}> = ({ displayMode, onClick }) => {
    const {
        preferredSuppliersAndStockLocations,
        approvedSuppliersAndStockLocations,
        nonApprovedSupplierAndStockLocations,
    } = useWatchSuppliersAndStockLocations();

    return (
        <ButtonGroup size={'large'}>
            <ButtonGroupItem
                selected={displayMode === 'approved'}
                onClick={() => onClick('approved')}
                count={new Set(preferredSuppliersAndStockLocations.concat(approvedSuppliersAndStockLocations)).size}
            >
                <Trans>Preferred and approved</Trans>
            </ButtonGroupItem>

            <ButtonGroupItem
                selected={displayMode === 'notApproved'}
                onClick={() => onClick('notApproved')}
                count={nonApprovedSupplierAndStockLocations.length}
            >
                <Trans>Not approved</Trans>
            </ButtonGroupItem>
        </ButtonGroup>
    );
};

const SetBackToDefaultButton: React.FunctionComponent = () => {
    const { setValue } = useFormContext<SourcingScenarioFormValues>();

    const { preferredSuppliersAndStockLocations, approvedSuppliersAndStockLocations } =
        useWatchSuppliersAndStockLocations();

    const { data: organizationSolutionPreferences } = useOrganizationSolutionPreferences();

    const handleOnClick = () => {
        setValue(
            'solution_preference.preferred_suppliers_and_stock_locations',
            organizationSolutionPreferences?.preferred_suppliers_and_stock_locations ?? [],
        );
        setValue(
            'solution_preference.approved_suppliers_and_stock_locations',
            organizationSolutionPreferences?.approved_suppliers_and_stock_locations ?? [],
        );
    };

    const disabled =
        isEqual(
            preferredSuppliersAndStockLocations,
            organizationSolutionPreferences?.preferred_suppliers_and_stock_locations ?? [],
        ) &&
        isEqual(
            approvedSuppliersAndStockLocations,
            organizationSolutionPreferences?.approved_suppliers_and_stock_locations ?? [],
        );

    return (
        <TertiaryButton size={'small'} disabled={disabled} onClick={handleOnClick}>
            <Trans>Set back to the default</Trans>
        </TertiaryButton>
    );
};

const SupplierPrefernceTableHeader: React.FunctionComponent<{
    displayMode: DisplayMode;
    selectionIds: string[];
    selectionStatus: SelectionStatus;
    toggleSelection: () => void;
    onClick: (displayMode: DisplayMode) => void;
    disabled: boolean;
}> = ({ displayMode, selectionIds, selectionStatus, toggleSelection, onClick, disabled }) => {
    return (
        <Flexbox padding={'8px 12px'} gap={'8px'} bgcolor={colorSystem.neutral[0]} alignItems={'center'}>
            <Checkbox
                size="small"
                checked={selectionStatus === 'all'}
                indeterminate={selectionStatus === 'indeterminate'}
                style={{ visibility: disabled ? 'hidden' : 'visible' }}
                onClick={toggleSelection}
            />
            <Text variant="h5">
                <Trans>Supplier name</Trans>
            </Text>
            <Flexbox flex={1} />
            {!disabled && (
                <Flexbox gap={6} alignItems={'center'}>
                    <Text variant="body-small">{t`Move ${selectionIds.length} selected to:`}</Text>
                    {displayMode !== 'approved' && (
                        <SecondaryButton
                            size={'small'}
                            disabled={selectionStatus === 'none'}
                            onClick={() => onClick('approved')}
                        >{t`Approved`}</SecondaryButton>
                    )}
                    {displayMode !== 'notApproved' && (
                        <SecondaryButton
                            size={'small'}
                            disabled={selectionStatus === 'none'}
                            onClick={() => onClick('notApproved')}
                        >{t`Not approved`}</SecondaryButton>
                    )}
                </Flexbox>
            )}
        </Flexbox>
    );
};

const PreferredSupplierInfo: React.FunctionComponent<{
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    preferredSuppliersAndStockLocations: string[];
    disabled: boolean;
    togglePreferred: (id: string) => void;
}> = ({ supplierAndStockLocation, preferredSuppliersAndStockLocations, disabled, togglePreferred }) => {
    const isPreferred = preferredSuppliersAndStockLocations.includes(supplierAndStockLocation.id);

    if (isPreferred) {
        return (
            <TertiaryButton
                size={'small'}
                disabled={disabled}
                onClick={() => togglePreferred(supplierAndStockLocation.id)}
                endIcon={<StarRounded style={{ color: colorSystem.primary[7] }} />}
            >
                <Text variant={'body-small'} color={colorSystem.primary[7]}>{t`Preferred`}</Text>
            </TertiaryButton>
        );
    }

    if (!disabled) {
        return (
            <TertiaryButton
                size={'small'}
                disabled={disabled}
                onClick={() => togglePreferred(supplierAndStockLocation.id)}
                endIcon={<StarBorderRounded style={{ color: colorSystem.primary[7] }} />}
            >
                <></>
            </TertiaryButton>
        );
    }
    return <></>;
};

function getItemDataAndSelectionStatus({
    tableDisplayMode,
    approvedSuppliersAndStockLocations,
    preferredSuppliersAndStockLocations,
    nonApprovedSupplierAndStockLocations,
    selectionIds,
    supplierAndStockLocations,
    query = '',
    comparator,
}: {
    tableDisplayMode: DisplayMode;
    preferredSuppliersAndStockLocations: string[];
    approvedSuppliersAndStockLocations: string[];
    nonApprovedSupplierAndStockLocations: string[];
    selectionIds: string[];
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    query: string | null;
    comparator: Comparable<string>;
}): { filteredItems: string[]; selectionStatus: SelectionStatus } {
    let itemData: string[] = [];
    let selectionStatus: SelectionStatus = 'none';

    switch (tableDisplayMode) {
        case 'approved':
            itemData = uniqueBy(
                approvedSuppliersAndStockLocations.concat(preferredSuppliersAndStockLocations),
                (s) => s,
            );
            break;
        case 'notApproved':
            itemData = nonApprovedSupplierAndStockLocations;
            break;
    }

    switch (selectionIds.length) {
        case 0:
            selectionStatus = 'none';
            break;
        case itemData.length:
            selectionStatus = 'all';
            break;
        default:
            selectionStatus = 'indeterminate';
            break;
    }

    const filteredSupplierIds = supplierAndStockLocations
        .filter(
            (s) =>
                !isPresent(query) ||
                formatSupplierAndStockLocationDTO(s).toLowerCase().includes(query.toLowerCase().trim()),
        )
        .map((s) => s.id);

    const filteredItems = itemData.filter((s) => filteredSupplierIds.includes(s)).sort(comparator);

    return { filteredItems, selectionStatus };
}

const SupplierPreferenceTable: React.FunctionComponent<{
    displayMode: DisplayMode;
    tableDisplayMode: DisplayMode;
    disabled: boolean;
    query: string | null;
}> = ({ displayMode, tableDisplayMode, disabled, query }) => {
    const { setValue, formState } = useFormContext<SourcingScenarioFormValues>();
    const [selectionIds, setSelectionIds] = React.useState<string[]>([]);

    const {
        preferredSuppliersAndStockLocations,
        approvedSuppliersAndStockLocations,
        nonApprovedSupplierAndStockLocations,
        supplierAndStockLocations,
    } = useWatchSuppliersAndStockLocations();
    const initiallyPreferred =
        formState.defaultValues?.solution_preference?.preferred_suppliers_and_stock_locations?.filter(isPresent) ??
        preferredSuppliersAndStockLocations;

    const comparator = createSupplierComparatorByIndex(supplierAndStockLocations, initiallyPreferred);

    const { filteredItems, selectionStatus } = getItemDataAndSelectionStatus({
        tableDisplayMode,
        selectionIds,
        query,
        comparator,
        supplierAndStockLocations,
        preferredSuppliersAndStockLocations,
        approvedSuppliersAndStockLocations,
        nonApprovedSupplierAndStockLocations,
    });

    // When switching the disabled flag, clear the selection state
    React.useEffect(() => {
        setSelectionIds([]);
    }, [disabled]);

    const toggleSelection = React.useCallback(() => {
        if (selectionIds.length === 0) {
            setSelectionIds(filteredItems);
        } else {
            setSelectionIds([]);
        }
    }, [selectionIds, setSelectionIds, filteredItems]);

    const onSelect = React.useCallback(
        (value: string) => {
            setSelectionIds((state) => (state.includes(value) ? state.filter((v) => v !== value) : [...state, value]));
        },
        [setSelectionIds],
    );

    const togglePreferred = (id: string) => {
        if (preferredSuppliersAndStockLocations.includes(id)) {
            setValue(
                'solution_preference.preferred_suppliers_and_stock_locations',
                preferredSuppliersAndStockLocations.filter((item) => item !== id),
            );
            setValue('solution_preference.approved_suppliers_and_stock_locations', [
                ...approvedSuppliersAndStockLocations,
                id,
            ]);
        } else {
            setValue(
                'solution_preference.approved_suppliers_and_stock_locations',
                approvedSuppliersAndStockLocations.filter((item) => item !== id),
            );
            setValue('solution_preference.preferred_suppliers_and_stock_locations', [
                ...preferredSuppliersAndStockLocations,
                id,
            ]);
        }
    };

    const handleMoveTo = (target: DisplayMode) => {
        function isSupplierNotSelected(supplierAndStockLocationId: string): boolean {
            return !selectionIds.includes(supplierAndStockLocationId);
        }
        const selectedPreferred: string[] = [];
        const selectedApproved: string[] = target === 'approved' ? selectionIds : [];

        // First we remove the selected items from the current shown list
        const newPreferred = preferredSuppliersAndStockLocations
            .filter(isSupplierNotSelected)
            .concat(selectedPreferred)
            .sort(comparator);

        const newApproved = approvedSuppliersAndStockLocations
            .filter(isSupplierNotSelected)
            .concat(selectedApproved)
            .sort(comparator);

        setValue('solution_preference.approved_suppliers_and_stock_locations', newApproved);
        setValue('solution_preference.preferred_suppliers_and_stock_locations', newPreferred);

        setSelectionIds([]);
    };

    const SupplierRow = ({ index, style, data }: { index: number; style: React.CSSProperties; data: string[] }) => {
        const value = data[index];
        const supplierAndStockLocation = supplierAndStockLocations.find((sals) => sals.id === value);

        if (!isPresent(supplierAndStockLocation)) {
            return <></>;
        }

        return (
            <Flexbox
                style={{ boxSizing: 'border-box', ...style }}
                alignItems={'center'}
                borderTop={`1px solid ${colorSystem.neutral[2]}`}
                paddingX={'12px'}
                gap={'8px'}
            >
                <Checkbox
                    size="small"
                    onClick={() => onSelect(value)}
                    checked={selectionIds.includes(value)}
                    style={{ visibility: disabled ? 'hidden' : 'visible' }}
                />

                <Text variant={'body-small'}>{formatSupplierAndStockLocationDTO(supplierAndStockLocation)}</Text>

                <Flexbox flex={1} />
                {hasSupplierTag(supplierAndStockLocation, SupplierTag.PcbSupplier) && (
                    <Tag color="neutral" attention="low" label={'PCB'} />
                )}
                {hasSupplierTag(supplierAndStockLocation, SupplierTag.CustomPartSupplier) && (
                    <Tag color="neutral" attention="low" label={t`Custom part`} />
                )}
                {hasSupplierTag(supplierAndStockLocation, SupplierTag.QuotePartner) && (
                    <Tag color="teal" attention="low" label={t`Quote partner`} />
                )}
                {tableDisplayMode === 'approved' && (
                    <PreferredSupplierInfo
                        supplierAndStockLocation={supplierAndStockLocation}
                        preferredSuppliersAndStockLocations={preferredSuppliersAndStockLocations}
                        togglePreferred={togglePreferred}
                        disabled={disabled}
                    />
                )}
            </Flexbox>
        );
    };

    return (
        <Box
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '8px',
                // This ensure that the scroll position is persisted while switching between view modes
                display: displayMode === tableDisplayMode ? undefined : 'none',
                overflow: 'hidden',
            }}
        >
            <SupplierPrefernceTableHeader
                displayMode={displayMode}
                selectionIds={selectionIds}
                selectionStatus={selectionStatus}
                toggleSelection={toggleSelection}
                onClick={handleMoveTo}
                disabled={disabled}
            />
            {filteredItems.length === 0 && !isPresent(query) ? (
                <CenteredLayout height={380}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No supplier in this category</Trans>
                    </Text>
                </CenteredLayout>
            ) : filteredItems.length === 0 && isPresent(query) ? (
                <CenteredLayout height={380}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>Sorry, your filter produced no results</Trans>
                    </Text>
                </CenteredLayout>
            ) : (
                <FixedSizeList
                    itemData={filteredItems}
                    itemCount={filteredItems.length}
                    width="100%"
                    height={380}
                    itemSize={32}
                    overscanCount={5}
                >
                    {SupplierRow}
                </FixedSizeList>
            )}
        </Box>
    );
};

export const SupplierPreferenceSection: React.FunctionComponent<{ disabled: boolean }> = ({ disabled }) => {
    const [displayMode, setVieMode] = React.useState<DisplayMode>('approved');
    const [query, setQuery] = React.useState<string | null>(null);

    const handleDisplayModeChange = React.useCallback(
        (displayMode: DisplayMode) => {
            setVieMode(displayMode);
        },
        [setVieMode],
    );

    return (
        <Flexbox flexDirection="column" gap={12} style={{ width: '800px' }}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <DisplayModeButtonGroup displayMode={displayMode} onClick={handleDisplayModeChange} />
                <SetBackToDefaultButton />
            </Flexbox>
            <FieldText
                type="search"
                value={query}
                onChange={(value) => setQuery(value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search style={{ color: colorSystem.neutral[6] }} />
                        </InputAdornment>
                    ),
                }}
            />
            {/* We render all tables at once, but only one is visible at a time */}
            <SupplierPreferenceTable
                displayMode={displayMode}
                tableDisplayMode={'approved'}
                disabled={disabled}
                query={query}
            />
            <SupplierPreferenceTable
                displayMode={displayMode}
                tableDisplayMode={'notApproved'}
                disabled={disabled}
                query={query}
            />
        </Flexbox>
    );
};
